<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div>
    <b-button
      class="btn btn-gradient-primary mb-1 mr-50 col-md-2"
      @click="$router.go(-1)"
    >
      <i class="fas fa-arrow-left mr-25" /> {{ $t('returnToList') }}
    </b-button>
    <b-button
      class="btn btn-gradient-success mb-1 ml-25 mr-50 col-md-2"
      @click="Submit()"
    >
      {{ $t('save') }}
    </b-button>
    <b-button
      v-if="items.status == 1"
      class="btn btn-gradient-success mb-1 ml-25 mr-50 col-md-2"
      @click="approve()"
    >
      <i class="far fa-check mr-50" /> {{ $t('approve') }}
    </b-button>
    <b-button
      v-if="items.status == 1"
      class="btn btn-gradient-danger mb-1 ml-25 mr-50 col-md-2"
      @click="cancelitem()"
    >
      <i class="far fa-ban mr-50" /> {{ $t('cancelOrder') }}
    </b-button>

    <b-row class="match-height">
      <b-col md="6">

        <div class="card">
          <div class="header-cardx p-2">
            <h3 class="mb-0">
              {{ $t('details') }} {{ items.itemnumber_text }}
            </h3>
          </div>

          <div class="p-2">
            <b-row>
              <b-col md="12">
                <b-form-group
                  label-for="h-paecelCode"
                  label-cols="3"
                  label-cols-lg="3"
                  label-cols-md="3"
                >
                  <template v-slot:label>
                    {{ $t('orderNumber') }} :
                  </template>

                  <p class="mt-50 font-weight-bolder">
                    {{ items.itemnumber_text }}
                  </p>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                  label-for="h-paecelCode"
                  label-cols="3"
                  label-cols-lg="3"
                  label-cols-md="3"
                >
                  <template v-slot:label>
                    {{ $t('customerCode') }} :
                  </template>
                  <div class="mt-50 font-weight-bolder">
                    <router-link
                      :to="{name : 'admin-employee-edit', params: {id: items.uid}}"
                    >
                      {{ items.username }}
                    </router-link>
                    <i
                      v-if="items.username"
                      v-clipboard:copy="items.username"
                      :class="iconClass[items.username] ? 'fal fa-clipboard-check text-success' : 'fal fa-copy text-primary'"
                      @click="handleCopy(items.username)"
                    />
                  </div>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                  label-for="h-paecelCode"
                  label-cols="3"
                  label-cols-lg="3"
                  label-cols-md="3"
                >
                  <template v-slot:label>
                    {{ $t('status') }} :
                  </template>
                  <p
                    v-if="items.status == 1"
                    class="mt-50 mb-0 badge badge-light-primary"
                  >
                    {{ $t('pendingReview') }}
                  </p>
                  <p
                    v-if="items.status == 2"
                    class="mt-50 mb-0 badge badge-light-success"
                  >
                    {{ $t('operationCompleted') }}
                  </p>
                  <p
                    v-if="items.status == 0"
                    class="mt-50 mb-0 badge badge-light-secondary"
                  >
                    {{ $t('cancelled') }}
                  </p> <span
                    v-if="items.status == 0"
                    class="text-danger"
                  >* {{ items.cancel_by }}</span>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                  label-for="h-paecelCode"
                  label-cols="3"
                  label-cols-lg="3"
                  label-cols-md="3"
                >
                  <template v-slot:label>
                    {{ $t('taobaoID') }} :
                  </template>
                  <p class="mt-50 font-weight-bolder">
                    {{ items.taobao_username ? items.taobao_username: '-' }}
                    <i
                      v-if="items.taobao_username"
                      v-clipboard:copy="items.taobao_username"
                      :class="iconClass[items.taobao_username] ? 'fal fa-clipboard-check text-success' : 'fal fa-copy text-primary'"
                      @click="handleCopy(items.taobao_username)"
                    />
                  </p>

                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label-for="h-paecelCode"
                  label-cols="3"
                  label-cols-lg="3"
                  label-cols-md="3"
                >
                  <template v-slot:label>
                    {{ $t('taobaoPassword') }} :
                  </template>
                  <p class="mt-50 font-weight-bolder">
                    {{ items.taobao_password ? items.taobao_password: '-' }}
                    <i
                      v-if="items.taobao_password"
                      v-clipboard:copy="items.taobao_password"
                      :class="iconClass[items.taobao_password] ? 'fal fa-clipboard-check text-success' : 'fal fa-copy text-primary'"
                      @click="handleCopy(items.taobao_password)"
                    />
                  </p>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                  label-for="h-paecelCode"
                  label-cols="3"
                  label-cols-lg="3"
                  label-cols-md="3"
                >
                  <template v-slot:label>
                    {{ $t('alipayID') }} :
                  </template>
                  <p class="mt-50 font-weight-bolder">
                    {{ items.alipay_username ? items.alipay_username: '-' }}
                    <i
                      v-if="items.alipay_username"
                      v-clipboard:copy="items.alipay_username"
                      :class="iconClass[items.alipay_username] ? 'fal fa-clipboard-check text-success' : 'fal fa-copy text-primary'"
                      @click="handleCopy(items.alipay_username)"
                    />
                  </p>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                  label-for="h-paecelCode"
                  label-cols="3"
                  label-cols-lg="3"
                  label-cols-md="3"
                >
                  <template v-slot:label>
                    {{ $t('alipayPassword') }} :
                  </template>
                  <p class="mt-50 font-weight-bolder">
                    {{ items.alipay_password ? items.alipay_password: '-' }}
                    <i
                      v-if="items.alipay_password"
                      v-clipboard:copy="items.alipay_password"
                      :class="iconClass[items.alipay_password] ? 'fal fa-clipboard-check text-success' : 'fal fa-copy text-primary'"
                      @click="handleCopy(items.alipay_password)"
                    />
                  </p>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                  label-for="h-paecelCode"
                  label-cols="3"
                  label-cols-lg="3"
                  label-cols-md="3"
                >
                  <template v-slot:label>
                    {{ $t('paymentCode') }} :
                  </template>
                  <p class="mt-50 font-weight-bolder">
                    {{ items.alipay_code ? items.alipay_code: '-' }}
                    <i
                      v-if="items.alipay_code"
                      v-clipboard:copy="items.alipay_code"
                      :class="iconClass[items.alipay_code] ? 'fal fa-clipboard-check text-success' : 'fal fa-copy text-primary'"
                      @click="handleCopy(items.alipay_code)"
                    />
                  </p>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                  label-for="h-paecelCode"
                  label-cols="3"
                  label-cols-lg="3"
                  label-cols-md="3"
                >
                  <template v-slot:label>
                    {{ $t('attachedFile') }} :
                  </template>
                  <span class="d-flex justify-content-left">
                    <span
                      v-if="items.file_cus"
                      class="preview-image-containerv2 mr-2 d-flex justify-content-left"
                    >
                      <img
                        :src="GetImg('Alipay_file',items.file_cus)"
                        alt="Preview"
                        class=""
                        @click="showPicture(items.file_cus)"
                      >
                      <vue-image-lightbox-carousel
                        ref="lightbox"
                        :show="showLightbox"
                        :images="images"
                        :show-caption="false"
                        @close="closeBox()"
                      />
                    </span>
                  </span>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                  label-for="h-paecelCode"
                  label-cols="3"
                  label-cols-lg="3"
                  label-cols-md="3"
                >
                  <template v-slot:label>
                    {{ $t('mnemonic') }} :
                  </template>
                  <p class="mt-50 font-weight-bolder">
                    {{ items.remark_cus ? items.remark_cus: '-' }}
                  </p>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>

      <b-col md="6">

        <div class="card">
          <div class="header-cardx p-2">
            <h3 class="mb-0">
              {{ $t('key-56') }}
            </h3>
          </div>

          <div class="p-2">
            <b-row>
              <b-col md="12">
                <b-form-group
                  label-for="h-paecelCode"
                  label-cols="3"
                  label-cols-lg="3"
                  label-cols-md="3"
                >
                  <template v-slot:label>
                    {{ $t('transferAmount') }} :
                  </template>

                  <p class="mt-50 font-weight-bolder">
                    ¥ {{ Commas(items.amount) }}
                  </p>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                  label-for="h-paecelCode"
                  label-cols="3"
                  label-cols-lg="3"
                  label-cols-md="3"
                >
                  <template v-slot:label>
                    {{ $t('exchangeRate') }} :
                  </template>
                  <p class="mt-50 font-weight-bolder">
                    {{ items.rate }} ฿
                  </p>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                  label-for="h-paecelCode"
                  label-cols="3"
                  label-cols-lg="3"
                  label-cols-md="3"
                >
                  <template v-slot:label>
                    {{ $t('useSystemBalance') }} :
                  </template>
                  <p class="mt-50 font-weight-bolder">
                    {{ Commas(items.balance) }} ฿
                  </p>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                  label-for="h-paecelCode"
                  label-cols="3"
                  label-cols-lg="3"
                  label-cols-md="3"
                >
                  <template v-slot:label>
                    {{ $t('transferEvidence') }} :
                  </template>
                  <b-form-file
                    id="h-file1"
                    v-model="fileadmin"
                    accept=".jpg, .png, .jpeg"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    @change="fileImage"
                  />
                  <span class="mt-2 d-flex justify-content-center">
                    <span
                      v-if="fileadminimg"
                      class="preview-image-containerv2 mr-2 d-flex justify-content-center"
                    >
                      <img
                        :src="GetImg('Alipay_file',fileadminimg)"
                        alt="Preview"
                        class=""
                        @click="showPicture(fileadminimg)"
                      >
                      <div class="preview-image-overlays p-0">
                        <i
                          class="fas fa-times-circle cursor-pointer"
                          @click="deleteImageorder()"
                        />
                      </div>

                      <vue-image-lightbox-carousel
                        ref="lightbox"
                        :show="showLightbox"
                        :images="images"
                        :show-caption="false"
                        @close="closeBox()"
                      />
                    </span>
                  </span>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                  label-for="h-remark"
                  label-cols="3"
                  label-cols-lg="3"
                  label-cols-md="3"
                >
                  <template v-slot:label>
                    {{ $t('remarks') }} :
                  </template>
                  <b-form-textarea
                    id="h-remark"
                    v-model="remark"
                    type="text"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>

      <b-col md="12">
        <div class="card">
          <div class="header-cardx p-2">
            <h3 class="mb-0">
              {{ $t('relatedAmountList') }}
            </h3>
          </div>

          <div class="pl-2 pr-2">
            <b-table
              responsive="sm"
              :fields="fieldsRelevant"
              :items="Transations"
              show-empty
            >
              <template #empty="">
                <b-table-empty-slot>
                  <div class="text-center my-2">
                    <img
                      src="@/assets/images/anan-img/svg/doc_empty.svg"
                      alt="empty"
                      height="60px"
                    >
                    <p class="text-muted">
                      No Data
                    </p>
                  </div>
                </b-table-empty-slot>
              </template>

              <template #cell(amount)="data">
                <span :style="getAmountStyle(data.item.type, data.item.type_amount)">
                  {{ getAmountPrefix(data.item.type,data.item.type_amount) }} {{ Commas(data.item.amount) }} ฿
                </span>
              </template>
            </b-table>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormTextarea,
  BButton,
  BFormFile,
  BTable,
} from 'bootstrap-vue'
import moment from 'moment-timezone'
import VueImageLightboxCarousel from 'vue-image-lightbox-carousel'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormTextarea,
    BButton,
    BFormFile,
    BTable,
    VueImageLightboxCarousel,
  },
  data() {
    return {
      images: [],
      showLightbox: false,
      isActive: true,
      file: null,
      parcelCode: null,
      remark: null,
      Transaction: [],
      parcelList: [
        {
          value: '1',
          text: '1',
        },
        {
          value: '2',
          text: '2',
        },
        {
          value: '3',
          text: '3',
        },
      ],

      ID: this.$route.params.id,
      items: [],
      fileadmin: null,
      fileadminimg: null,
      Transations: [],
      iconClass: {},
    }
  },
  computed: {
    fieldsRelevant() {
      return [
        {
          key: 'created_at', label: this.$t('transactionDate'), thStyle: { width: '20%' }, formatter: value => moment(value).format('DD/MM/YYYY HH:mm:ss'),
        },
        { key: 'detail', label: this.$t('details'), thStyle: { width: '60%' } },
        {
          key: 'amount', label: this.$t('quantity'), thStyle: { width: '20%' }, formatter: value => `${this.Commas(value.toFixed(2))} ฿`,
        },
      ]
    },
  },
  mounted() {
    this.getData()
    this.getTransations()
  },
  methods: {
    async getData() {
      try {
        const params = {
          id: this.ID,
        }
        const { data: res } = await this.$http.get('/Alipayschina/getDataOne', { params })
        this.items = res
        this.fileadminimg = this.items.fileadmin
        this.remark = this.items.remark_admin
        // console.log(this.items)
        // console.log(res)
      } catch (err) {
        this.SwalError(err)
        console.log(err)
      }
    },
    async getTransations() {
      try {
        const params = {
          ref_id: this.ID,
        }
        const { data: res } = await this.$http.get('/transactions/show/ref', { params })
        if (res.success) {
          this.Transations = res.data
        }

        // console.log(res)
      } catch (err) {
        this.SwalError(err)
        console.log(err)
      }
    },
    handleCopy(item) {
      if (item) {
        this.$set(this.iconClass, item, true)

        setTimeout(() => {
          this.$set(this.iconClass, item, false)
        }, 3500)
      }
    },
    Submit() {
      this.$bvModal.msgBoxConfirm('บันทึกข้อมูล?', {
        title: 'ยืนยันข้อมูล',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'success',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      }).then(result => {
        if (result) {
          const obj = {
            // eslint-disable-next-line no-underscore-dangle
            id: this.items._id,
            fileadmin: this.fileadminimg,
            remark_admin: this.remark,
          }
          this.$http.post('/Alipayschina/Storedata', obj)
            .then(() => {
              this.getData()
              this.Success(this.$t('key-260'))
            })
            .catch(err => {
              this.SwalError(err)
              console.log(err)
            })
        }
      })
    },
    approve() {
      this.$bvModal.msgBoxConfirm(`อนุมัติรายการ ${this.items.itemnumber_text} ?`, {
        title: 'ยืนยันข้อมูล',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'success',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      }).then(result => {
        if (result) {
          // date_approve:
          const times = new Date()
          const obj = {
            // eslint-disable-next-line no-underscore-dangle
            id: this.items._id,
            fileadmin: this.fileadminimg,
            remark_admin: this.remark,
            status: 2,
            date_approve: times,
          }
          this.$http.post('/Alipayschina/Storedata', obj)
            .then(() => {
              this.getData()
              this.SendEmail(17)
              this.Success(this.$t('key-260'))
            })
            .catch(err => {
              this.SwalError(err)
              console.log(err)
            })
        }
      })
    },
    SendEmail(status) {
      const obj = {
        status,
        uid: this.items.uid,
        username: this.items.username,
        email: null,
        orderId_text: `${this.items.itemnumber_text}`,
        agent_id: this.items.agent_id,
        fileimg: null,
        path_name: 'alipay-edit',
        ref: this.items._id,
        fileadmin: this.items.fileadmin ? this.items.fileadmin : null,
        amount: this.items.amount,
        rate: this.items.rate,
        balance: this.items.balance,
      }
      this.$http.post('/order/SendEmail', obj)
        .then(() => {
          this.getData()
        })
        .catch(error => console.log(error))
    },
    cancelitem() {
      this.$bvModal.msgBoxConfirm(`ยกเลิกรายการ ${this.items.itemnumber_text} ?`, {
        title: 'ยืนยันข้อมูล',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      }).then(result => {
        if (result) {
          const times = new Date()
          const obj = {
            // eslint-disable-next-line no-underscore-dangle
            id: this.items._id,
            status: 0,
            date_approve: times,
          }
          this.$http.post('/Alipayschina/CancelAlipays', obj)
            .then(() => {
              this.getData()
              this.SendEmail(18)
              this.Success('ยกเลิกข้อมูลสำเร็จ')
            })
            .catch(err => {
              this.SwalError(err)
              console.log(err)
            })
        }
      })
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    getAmountStyle(amount, typeAmount) {
      if (amount === 21) {
          if (typeAmount) {
            return { color: 'green' }
          } else {
            return { color: 'red' }
          }
        } else {
            if ([1, 8, 11, 12, 17, 16].includes(amount)) {
              return { color: 'green' }
            }
            if ([2, 3, 4, 5, 6, 7, 13, 15, 18, 10, 9, 20].includes(amount)) {
              return { color: 'red' }
            }
        }
    },
    getAmountPrefix(value, typeAmount) {
      if (value === 21) {
          if (typeAmount) {
            return '+'
          } else {
            return '-'
          }
        } else {
            if ([1, 8, 11, 12, 17, 16].includes(value)) {
              return '+'
            }
            if ([2, 3, 4, 5, 6, 7, 13, 15, 18, 10, 9, 20].includes(value)) {
                return '-'
            }
        }
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    fileImage(event) {
      if (event) {
        this.fileadmin = event.target.files
        // this.fileimg_th = null
        const reader = new FileReader()
        const filepath = this.fileadmin[0]
        if (!filepath) {
          this.fileadminimg = null
        } else {
          reader.onload = e => {
            // console.log(e.target.result)
            // console.log(e.target.result)
            this.fileadminimg = e.target.result
            // console.log(this.filecusimg)
            // e.target.result
            // console.log(this.namemap)
          }
          reader.readAsDataURL(filepath)
        }
      }
    },
    showAdd() {
      this.$bvModal.show('add-addr')
    },
    showModal() {
      this.name = null
      this.phone = null
      this.address = null
      this.province = null
      this.district = null
      this.subdistrict = null
      this.postalcode = null
      this.$bvModal.show('modal-1')
    },
    deleteImageorder() {
      this.$bvModal.msgBoxConfirm('ต้องการลบรูปภาพหรือไม่ ?', {
        title: 'ยืนยันข้อมูล',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      }).then(result => {
        if (result) {
          this.fileadminimg = null
          this.fileadmin = null
        }
      })
    },
    GetImg(pathfile, filename) {
      const matches = filename.match(/^data:image\/([A-Za-z-+\/]+);base64,(.+)$/)
      if (!matches || matches.length !== 3) {
        return `${this.$http.defaults.baseURL}/viewimg?pathfile=${pathfile}/${filename}`
      }
      return filename
    },
    showPicture(picture) {
      this.showLightbox = true
      const img = { path: this.GetImg('Alipay_file', picture) }
      this.images.push(img)
      // this.$swal({
      //   imageUrl: picture,
      //   imageWidth: 400,
      //   imageHeight: 400,
      //   imageAlt: 'Custom image',
      //   confirmButtonText: 'ปิด',
      // })
    },
    closeBox() {
      this.showLightbox = false
      this.images = []
    },

  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
